import React from "react";

const Footer=()=>{
    return(
        <div style={{background:'#1f292e'}}>
            <div className="container">
                <div className="row">
                <div className="col-md-4">
                    <center><b style={{color:'#FF5733'}}><u>ABOUT US</u></b></center>
                    <p style={{textAlign: 'justify',color: '#fff'}}></p>
                </div>
                <div className="col-md-4">
                    <center><b style={{color:'#FF5733'}}><u>SERVICES</u></b></center>
                    {/* <ul style="color: #fff;">
                        <li>Networking</li>
                        <li>AMC</li>
                        <li>Web development</li>
                        <li>IOT</li>
                        <li>Printing FSMA contract </li>
                    </ul>  */}
                </div>
                <div className="col-md-4">
                    <center><b style={{color:'#FF5733'}}><u>CONTACT</u></b></center>
                    <span style={{color: '#fff'}}><b>Address:-</b> C-187, Nirala Nagar Lucknow<br/>
                    <b>TOLL FREE SUPPORT :-</b> 1800 313 9020<br/>
                    <b>Email :-</b> deezoneitsolutions@gmail.com<br/></span>
                </div>
                </div>
            </div>	
         </div>
    )
}
export default Footer;