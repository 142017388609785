import React from "react";
const Header = () => {
    return (
        <>
            <div className="container-fluid" style={{ background: '#122e63', height: '180px' }}>
                <div className="row">
                    <div className="col-sm-2">
                        <a href={'/'}><img src="images/logo.png" alt="img" style={{ width: '100%', height: '180px', margin: '10px' }} /></a>
                    </div>
                    <div className="col-sm-8" style={{ padding: '50px' }}>
                        <h3 style={{ fontweight: '900', color: '#F9722D', fontfamily: 'Georgia, serif' }}><b>DEEZONE IT SOLUTIONS PRIVATE LIMITED</b></h3>
                        <h3 style={{ fontweight: '900', color: '#fff', fontfamily: 'Georgia, serif' }}><b>Ultimate Destination For IT Hardware And Solutions</b></h3>
                    </div>
                    <div className="col-sm-2" style={{ padding: '60px' }}>
                        <a href="https://deezone.linker.store/" className="btn btn-success navbar-right" target="_blank" style={{ width: '155px', height: '50px', fontSize: '20px', padding: '10px', marginRight: '10px' }}><b>VISIT STORE <i className
                            ="fa fa-angle-double-right"></i></b></a>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Header;