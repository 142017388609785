import React from "react";
import ReactDOM from 'react-dom';
import './Styles.css';
// this is slider 1 package react-alice-carousel
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import Slider from "react-slick";
// this is slider 2 package 'better-react-carousel'
import Carousel from 'better-react-carousel';

const Home=()=>{
   //  this is slide 1
   var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
  autoplaySpeed: 3000
    };
   
   //  this is slide 2 
    var slider2={
        breakpoint: 500,
        cols: 5,
        rows: 1,
        gap: 10,
        loop: true,
        autoplay: 4000,
 
      }
    
   
   return(
    <>
    
     <div className="container-fluid mt-4" style={{backgroundColor:'#F3F1F1'}}>
      <div className="row">
       <div className="col-lg-6">
       <a href={'https://docs.google.com/forms/d/e/1FAIpQLSfi_81OQwZwFEr43NkL4rSYE4xuIiYrveHYUZ4EPTnTJ2vg7w/viewform'} className="btn btn-primary navbar-right mb-1" target="_blank" style={{width:'255px',height:'40px',fontSize:'15px',padding:'5px',marginRight:'10px'}}><b>Request Pickup for Data Recovery <i className
            ="fa fa-angle-double-right"></i></b></a> 
       <AliceCarousel autoPlay autoPlayInterval="3000">
         <img src="images/slider2/slide1.jpg" className="sliderimg"/>
         <img src="images/slider2/image1.jpeg" className="sliderimg"/>
         <img src="images/slider2/image4.jpeg" className="sliderimg"/>
         <img src="images/slider2/image12.jpeg" className="sliderimg"/>
         <img src="images/slider2/image14.jpeg" className="sliderimg"/>
         <img src="images/slider2/image8.jpeg" className="sliderimg"/>
         <img src="images/slider2/image6.jpeg" className="sliderimg"/>
         <img src="images/slider2/image7.jpeg" className="sliderimg"/>
         <img src="images/slider2/image5.jpeg" className="sliderimg"/>
         <img src="images/slider2/image9.jpeg" className="sliderimg"/>
      </AliceCarousel>
       </div>
       <div className="col-lg-6" style={{backgroundColor:'#fff'}}>
         <span style={{fontSize:'28px',marginRight:'19%'}}><b>Today’s popular picks</b></span>
         <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter">
         Register Your Requirement
         </button><hr/><br/>
         <div className="row">
          <div className="col-sm-4" >
            <img src="images/laptop.jpg"  style={{width:'90%',height:'120px'}} />
            <p><b>Laptop</b><br />Intel Core i3-7020U.<br/> 4 GB RAM,1 TB HDD.<br/>Windows 10.</p>
         </div>
        <div className="col-sm-4">
            <img src="images/pc.jpg"  style={{width:'90%',height:'120px'}} />
            <p><b>Desktop</b><br/>Intel Core i3-7020U.<br/> 4 GB RAM,1 TB HDD.<br/>Windows 10.</p>
         </div>
         <div className="col-sm-4">
            <img src="images/printer.jpg"  style={{width:'90%',height:'120px'}} />
            <p><b>Brother Printer</b><br/>Printing Type: Colour. Paper Size: A4, A5, A6. 10 PPM Colour Speed.</p>
         </div>
            <div className="col-sm-4 mt-4">
            <img src="images/cctv.jpg" style={{width:'90%',height:'120px'}} />
            <p><b>CCTV</b><br/>Colour/Monochrome. <br/>1/3 inch.</p>
         </div>
            <div className="col-sm-4 mt-4">
            <img src="images/projector.jpg" style={{width:'90%',height:'120px'}} />
            <p><b> Projector</b><br/>1024 × 768 pixels.</p>
         </div>
            <div className="col-sm-4 mt-4">
            <img src="images/toner.jpg" style={{width:'90%',height:'120px'}} />
            <p><b>Toner</b><br/>Standard Capacity Cartridges. Cartridge color Black.</p>
         </div> 
         </div> 
        </div>
       </div>
       <br/>
       <div className="container-fluid mt-4">
          <h2 style={{color:'#FF5733',fontWeight: '800'}}><center>Featured Brands</center></h2><hr/>
          <Carousel  {...slider2}>
            <Carousel.Item>
            <img src="images/slider/amd.png"  alt="1" style={{height:'80px',paddingTop:'20px'}} />
            </Carousel.Item>
            <Carousel.Item>
            <img src="images/slider/aoc.png" alt="2"  style={{height:'100px',paddingLeft:'30px'}} />
            </Carousel.Item>
            <Carousel.Item>
            <img src="images/slider/benq.png" alt="3"  style={{height:'90px',paddingTop:'20px'}} />
            </Carousel.Item>
            <Carousel.Item>
            <img src="images/slider/asrok1.png" alt="4"  style={{width:'150px',height:'90px',paddingTop:'10px',paddingLeft:'30px'}} />
            </Carousel.Item>
            <Carousel.Item>
            <img src="images/slider/gb.png" alt="5"  style={{width:'150px', height:'80px',paddingTop:'20px'}} />
            </Carousel.Item>
            <Carousel.Item>
            <img src="images/slider/lg.png" alt="6"  style={{height:'90px',paddingTop:'20px'}} />
            </Carousel.Item>
            <Carousel.Item>
            <img src="images/slider/msi.png" alt="7"  style={{height:'100px'}} />
            </Carousel.Item>
            <Carousel.Item>
            <img src="images/slider/mssoft.png" alt="8"  style={{width:'150px',height:'100px'}} />
            </Carousel.Item>
            <Carousel.Item>
            <img src="images/slider/seagate.png" alt="9"  style={{height:'90px',paddingTop:'10px'}} />
            </Carousel.Item>
            <Carousel.Item>
            <img src="images/slider/toshiba.png" alt=""  style={{height:'110px'}} />
            </Carousel.Item>
            <Carousel.Item>
            <img src="images/slider/esc.png" alt=""  style={{height:'90px',paddingTop:'10px'}} />
            </Carousel.Item>
            <Carousel.Item>
            {/* anything you want to show in the grid */}
            </Carousel.Item>
            {/* ... */}
          </Carousel>
         </div>  {/* close div */}
       <hr/>
      <br/>
      <div className="row pt-2 pl-2 pb-2 pr-2">
         <div className="col-lg-6" style={{backgroundColor:'#fff',borderRadius: '5px',border:'1px solid'}}>
            <h3><b>Latest Offering in Laptop</b></h3><hr/>
            <div className="row">
               <div className="col-sm-12 mr-2">
               <img src="images/offer_taptop.png" style={{width:'90%', height: '300px',paddingLeft: '10%'}} /><br/>
               <h4>HP Laptop</h4>
               <ul style={{color:'#007db8'}}>
                  <li>Latest 8th Gen Intel® Core™ i7 processor (i7 8750H)</li>
                  <li>Windows 10 Home / Windows 10 Pro</li>
                  <li>17.3" Full HD (1920x1080) IPS level display</li>
                  <li>Metallic top and keyboard cover paired with futuristic design</li>
               </ul>

               </div>
            </div>
         </div>
         <div className="col-lg-6" style={{backgroundColor:'#fff',borderRadius: '5px',border:'1px solid'}}>
            <h3><b>Latest Offering in Printing</b></h3><hr/>
            <div className="row">
               <div className="col-sm-12 mr-2">
               <img src="images/canan.jpg"  style={{width:'90%', height: '300px',paddingLeft: '10%'}} />
               <h4>Printer</h4>
               <ul style={{color:'#007db8'}}>
                  <li>53.5 dB A4 black & white plain paper acoustic noise</li>
                  <li>Hi-Speed USB 2.0 interface, CIS scanning method</li>
                  <li>Supports Windows 7 & above operating systems</li>
                  <li>Flatbed scanner, 600 x 1200 dpi scan resolution</li>
               </ul> 

               </div>
            </div>
         </div>
      </div>
      <br/>
      <hr/>

      <div className="row pt-4 pl-2 pb-2 pr-2">
         <div className="col-lg-6" style={{backgroundColor:'#fff',borderRadius: '5px',border:'1px solid'}}>
            <h3><b>Latest Offering in Services</b></h3><hr/>
            <div className="row">
               <div className="col-sm-6">
               <p><b>Web development</b></p>
               <img src="images/web-site-development-services.jpg" style={{width:'100%',height:'200px',paddingLeft: '10%'}} />
               </div>
               <div className="col-sm-6">
               <p><b>AMC</b></p>
               <img src="images/annual-maintenance-contract.png" style={{width:'100%',height:'200px',paddingLeft: '10%'}} />
               </div>
               <div className="col-sm-6">
               <p><b>Networking</b></p>
               <img src="images/nt.jpg" style={{width:'100%',height:'200px',paddingLeft: '10%'}} />
               </div>
               
               <div className="col-sm-6">
               <p><b>Printing FSMA contract</b></p>
               <img src="images/fsma.jpg" style={{width:'100%',height:'200px',paddingLeft: '10%'}} />
               </div>
            </div>
         </div>
         <div className="col-lg-6" style={{backgroundColor:'#fff',borderRadius: '5px',border:'1px solid'}}>
            <h3><b>Latest Offering in Smart Solution</b></h3><hr/>
               <div className="row">
               <div className="col-sm-12 mr-2 ">
               <img src="images/smartsolution1.jpg" style={{width:'90%', height: '300px',paddingLeft: '10%'}} /><br/>
               <h4></h4>
               <ul style={{color:'#007db8'}}>
                  <li>Systems Architecture – flexible and customized solutions for today’s technologies</li>
                  <li>Networking – firewalls, routers, switches, SDN technologies and SLA compliance</li>
                  <li>Surveillance Cameras – software and hardware to support video transmission, monitoring, recording and management</li>
                  <li>Metallic top and keyboard cover paired with futuristic design</li>
               </ul> 

               </div>
            </div>
         </div>
      </div>
      <br/>
      <hr/>
      <br/>

      <div className="row pt-2 pl-2 pb-2 pr-2">
         <div className="col-lg-6" style={{backgroundColor:'#fff',borderRadius: '5px',border:'1px solid'}}>
            <h3><b>Latest Offering in Gaming</b></h3>
            <div className="row">
               <div className="col-md-12 mr-2">
               <img src="images/game1.jpg" style={{width:'90%', height: '300px',paddingLeft: '10%'}} /><br/>
               <h4>HP Laptop</h4>
               <ul style={{color:'#007db8'}}>
                  <li>Operating System: Windows 10.</li>
                  <li>Processor: Intel Core i5-6600K @ 3.5 GHz</li>
                  <li>Memory: 8 GB RAM</li>
                  <li>Graphics: NVIDIA GeForce GTX 1070 8GB.</li>
                  <li>Hard Drive: 1TB (SSD or HDD)</li>
               </ul>

               </div>
            </div>
         </div>
         <div className="col-lg-6" style={{backgroundColor:'#fff',borderRadius: '5px',border:'1px solid'}}>
            <h3><b>Latest offering in Security & Surveillance</b></h3><hr/>
            <div className="row">
               <div className="col-sm-12 mr-2">
               <img src="images/hikvision.png" style={{width:'90%', height: '300px',paddingLeft: '10%'}} /><br/>
               <h4>Hikvision 1MP HD CCTV</h4>
               <ul style={{color:'#007db8'}}>
                  <li>Hard Drive: 1TB (SSD or HDD)</li>
                  <li>HikVision 1 MP HD Dome Camera IR Distance 20 Mtrs.</li>
                  <li>HikVision 720p Turbo HD 4 Channel DVR DS-7A04HGHI</li>
                  <li>Seagate 1TB Surveillance Hard Drive For CCTV storage</li>
               </ul>

               </div>
            </div>
         </div>
         </div>
    
         <h3><b>Products</b></h3>
         <div class="row pt-2 pl-2 pb-2 pr-2" style={{border: '1px solid',borderRadius:'10px',backgroundColor:'#fff'}}>
            <div class="col-sm-2">
            <span style={{color:'#FF5733'}}><b>Products</b></span>
            <ul style={{margin: '0px',padding: '0px'}}>
               <li style={{listStyle:'none'}}>Laptop</li>
               <li style={{listStyle:'none'}}>Desktop</li>
               <li style={{listStyle:'none'}}>Server</li>
               <li style={{listStyle:'none'}}>Workstation</li>
               <li style={{listStyle:'none'}}>Mini pc</li>
               <li style={{listStyle:'none'}}>Memory</li>
               <li style={{listStyle:'none'}}>Mouse</li>
               <li style={{listStyle:'none'}}>Keyboard</li>
               <li style={{listStyle:'none'}}>Printer</li>
               <li style={{listStyle:'none'}}>Copier</li>
               <li style={{listStyle:'none'}}>Scanner</li>
               <li style={{listStyle:'none'}}>CCTV </li>
               <li style={{listStyle:'none'}}>HDD</li>
               <li style={{listStyle:'none'}}>SSD</li>
            </ul>
            </div> 
            <div class="col-sm-2">
            <span  style={{color:'#FF5733'}}><b>Printing</b></span>
            <ul style={{margin: '0px',padding: '0px'}}>
               <li style={{listStyle:'none'}}>A3 copiers</li>
               <li style={{listStyle:'none'}}>Duplicating printer</li>
               <li style={{listStyle:'none'}}>A4 Laser Ink printer</li>
               <li style={{listStyle:'none'}}>A3 Laser ink printer</li>
               <li style={{listStyle:'none'}}>Photo printer</li>
               <li style={{listStyle:'none'}}>Dot matrix printer</li>
               <li style={{listStyle:'none'}}>Laser Printers.</li>
               <li style={{listStyle:'none'}}>Solid Ink Printers</li>
               <li style={{listStyle:'none'}}>LED Printers</li>
               <li style={{listStyle:'none'}}></li>
               <li style={{listStyle:'none'}}>Multifunction Printers</li>
            </ul>
            
            </div> 
            <div class="col-sm-2">
            <span style={{color:'#FF5733'}}><b>Gaming</b></span>
            <ul style={{margin: '0px',padding: '0px'}}>
               <li style={{listStyle:'none'}}>Laptop</li>
               <li style={{listStyle:'none'}}>Desktop</li>
               <li style={{listStyle:'none'}}>Accessories</li>
               <li style={{listStyle:'none'}}>Storage</li>
               <li style={{listStyle:'none'}}>Server</li>
               <li style={{listStyle:'none'}}>Operating system</li>
               <li style={{listStyle:'none'}}>Headphone</li>
               <li style={{listStyle:'none'}}>Motherboard</li>
               <li style={{listStyle:'none'}}>Memory</li>
               <li style={{listStyle:'none'}}>Graphics processing unit</li>

            </ul>
            
            </div> 
            <div class="col-sm-2">
            <span style={{color:'#FF5733'}}><b>Networking</b></span>
            <ul style={{margin: '0px',padding: '0px'}}>
               <li style={{listStyle:'none'}}>Switch</li>
               <li style={{listStyle:'none'}}>Router</li>
               <li style={{listStyle:'none'}}>Bridge</li>
               <li style={{listStyle:'none'}}>Hub</li>
               <li style={{listStyle:'none'}}>Gateway</li>
               <li style={{listStyle:'none'}}>Modem</li>
               <li style={{listStyle:'none'}}>Wireless</li>
               <li style={{listStyle:'none'}}>Network security</li>
               <li style={{listStyle:'none'}}>VPN Router</li>
               <li style={{listStyle:'none'}}>Broadband Router</li>
               <li style={{listStyle:'none'}}>WiFi Router</li>
            </ul>
            
            </div> 
            <div class="col-sm-2">
            <span style={{color:'#FF5733'}}><b>Securety & Surveillance</b></span>
            <ul style={{margin: '0px',padding: '0px'}}>
               <li style={{listStyle:'none'}}>Network Security</li>
               <li style={{listStyle:'none'}}>Security Analysis</li>
               <li style={{listStyle:'none'}}>Denial of service( DoS)</li>
               <li style={{listStyle:'none'}}>Wireless attack</li>
               <li style={{listStyle:'none'}}>Physical attack</li>
               <li style={{listStyle:'none'}}>Firewalls & UTMs</li>
               <li style={{listStyle:'none'}}>Physical Security & IP Cameras</li>

               <li style={{listStyle:'none',color:'#FF5733'}}><b>Voice Products</b></li>
               <li style={{listStyle:'none'}}>Cisco</li>
               <li style={{listStyle:'none'}}>Hosted UC</li>
               <li style={{listStyle:'none'}}>Ring Central</li>
               <li style={{listStyle:'none'}}>SoTel</li>
            </ul>
            
            </div> 
            <div class="col-sm-2">
            <span style={{color:'#FF5733'}}><b>Accessories</b></span>
            <ul style={{margin: '0px',padding: '0px'}}>
               <li style={{listStyle:'none'}}>Laptop Accessories</li>
               <li style={{listStyle:'none'}}>Storage Accessories</li>
               <li style={{listStyle:'none'}}>Input Accessories</li>
               <li style={{listStyle:'none'}}>Projector Accessories</li>
               <li style={{listStyle:'none'}}>Scanner Accessories</li>
               <li style={{listStyle:'none'}}>Printer Accessories</li>

               <li style={{listStyle:'none',color:'#FF5733'}}><b>Software</b></li>
               <li style={{listStyle:'none'}}>Deveolpment Tools</li>
               <li style={{listStyle:'none'}}>Sofatware Suite</li>
               <li style={{listStyle:'none'}}>Network Software</li>
               <li style={{listStyle:'none'}}>Utilities</li>
            </ul>
            </div> 
         </div>
       </div>

    </>
   )
}

export default Home;
